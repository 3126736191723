// import Layout from "./components/layout";
// import Slider from "./components/slider/slider";
import AppRoutes from "./routes/Routes";
import "./App.css"

function App() {
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
