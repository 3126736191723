import React from "react";
import Layout from "./layout";

function Webinars() {
  return (
    <Layout>

    </Layout>
  );
}

export default Webinars;
